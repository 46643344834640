import Loading from '../../components/loading/loading.vue';
import OpenService from '../../services/open';
import ItineraryService from '../../services/itinerary';
import MItSection from '../../components/m-it-section/m-it-section.vue';

export default {
  name: 'ManageItineraries',

  methods: {

    generateSlug(string) {
      return OpenService.generateSlug(string);
    },

    setFilteredItineraries() {
      this.offeringItineraries = this.itineraries.offering;
      this.travelingItineraries = this.itineraries.traveling;

      this.offeringItineraries.forEach(x => {
        return ItineraryService.mapItinerary(x);
      });

      this.travelingItineraries.forEach(x => {
        return ItineraryService.mapItinerary(x);
      });

      this.activeItineraries = this.offeringItineraries.filter(x => x.status === 'active');
      this.inactiveItineraries = this.offeringItineraries.filter(x => x.status === 'indraft' || x.status === 'inactive');

      this.unlockedItineraries = this.travelingItineraries.filter(x => x.type === 'booked');
      this.savedItineraries = this.travelingItineraries.filter(x => x.type === 'saved');
    },

    fetchItineraries() {
      this.loading = true;
      const uuid = this.$store.state.uuid;
      ItineraryService
        .getItinerariesById(uuid)
        .then(r => {
          this.itineraries = r.data;
          this.setFilteredItineraries();
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    deleteItem(exp) {
      this.beingRemoved = exp;
      this.confirmModal = true;
    },

    changeStatus(status) {
      this.saving = true;
      const itId = this.beingRemoved._id;
      ItineraryService
        .changeStatus(itId, status, true)
        .then(() => {
          this.offeringItineraries.find(x => x._id === itId).status = status;
          this.setFilteredItineraries();
          this.confirmModal = this.saving = false;
        })
        .catch(e => {
          console.log(e);
          this.confirmModal = this.saving = false;
        })
    }
  },

  components: {
    Loading,
    MItSection
  },

  mounted() {
    this.fetchItineraries();
  },

  data() {
    return {
      dType: 'offering',
      loading: false,
      confirmModal: false,
      saving: false,
      beingRemoved: {},

      lazy: require("@/assets/imgs/placeholder.png"),
      itineraries: {},

      offeringItineraries: [],
      travelingItineraries: [],

      activeItineraries: [],
      inactiveItineraries: [],

      unlockedItineraries: [],
      savedItineraries: []

    }
  }
}